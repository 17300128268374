/* eslint-disable import/no-cycle */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'src/i18n';
import classnames from 'classnames';
import { useQuery, useMutation } from 'utils/react-query';
import Loader from 'components/uiLibrary/Loader';
import Typography from 'components/uiLibrary/Typography';
import EntityCard from 'components/Globals/EntityCard';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import useTracking from 'components/Globals/Analytics';
import Checkbox from 'components/uiLibrary/Inputs/Checkbox';
import { enqueueSnackbar, SNACKBAR_VARIANTS } from 'components/uiLibrary/Snackbar';
import { PrimaryButton, QuaternaryButton } from 'components/uiLibrary/LinkButton';
import useQuickView, { QUICK_VIEW_TYPES } from 'components/Globals/Layout/QuickView';
import {
  COMPONENTS,
  SECTIONS,
  SUB_COMPONENTS,
  ACTION_CALLBACK_STEPS,
  ACTION_CALLBACK_STATUS,
} from 'components/Globals/Analytics/constants';
import AutocompleteDropDown from 'components/uiLibrary/FormInputs/AutocompleteDropDown';

import { TAG_TYPE, TAG_SLUGS, REGISTRATION_STEPS } from 'containers/Accounts/constants';
import orgQueries from 'containers/Organizations/queries';
import queries, { useUpdateRegistrationMutation } from 'containers/Accounts/queries';

import { TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import { errorMessageConverter } from 'utils/common';
import usePageContext from 'utils/hooks/usePageContext';
import { submitHubspotForm } from 'utils/formUtils';
import { useUserData, useRefetchUserQueries, useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import { getOption } from 'utils/globals/app';
import { KEYBOARD_KEY_VALUES } from 'utils/hooks/useOnKeypress';

import classes from './NewProfessionalEmail.module.scss';

const FORM_FIELD_NAME = {
  DESIGNATION: 'designation',
  PROFESSION: 'profession',
};

const FORM_FIELDS = [
  {
    name: FORM_FIELD_NAME.DESIGNATION,
    label: `${TP}.JOB_TITLE_DESIGNATION`,
  },
  {
    name: FORM_FIELD_NAME.PROFESSION,
    label: `${TP}.FN_PROFESSION`,
  },
];

const getOrgEmployeePayload = (userData, registration, selectedOrgId, selectedPreferences, ownerProfile, jobInfo) => ({
  data: {
    firstName: registration?.meta_data?.first_name || ownerProfile?.profile?.firstName,
    lastName: registration?.meta_data?.last_name || ownerProfile?.profile?.lastName,
    roles: [
      {
        profession: registration?.entities?.organizationEmployee?.profession || jobInfo?.profession?.id,
        designation: registration?.entities?.organizationEmployee?.designation || jobInfo?.designation?.id,
      },
    ],
    preferences: selectedPreferences?.map(preference => ({
      id: preference?.id,
    })),
    workEmail: {
      email: registration?.meta_data?.contact?.email || userData?.email,
      privacyAttributes: [
        {
          id: 1,
        },
      ],
    },
    profile: {
      id: registration?.entities?.profile?.id || ownerProfile?.profile?.id,
      firstName: userData.firstName || ownerProfile?.profile?.firstName,
      lastName: userData.lastName || ownerProfile?.profile?.lastName,
    },
    organization: {
      id: selectedOrgId,
    },
  },
  endpointParams: selectedOrgId,
});

const OrganizationCard = ({
  organization,
  setSelectedOrgId,
  quickView = false,
  trackingData,
  submitHubSpotFormForSelectOrg,
  userData,
}) => {
  const track = useTracking();
  return (
    <div
      className={classes.organizationCard}
      onClick={() => {
        submitHubSpotFormForSelectOrg(userData, organization);
        setSelectedOrgId(organization.id);
        track.click({
          ...trackingData,
          meta: {
            id: organization.id,
          },
        });
      }}
      role="button"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === KEYBOARD_KEY_VALUES.ENTER || e.key === KEYBOARD_KEY_VALUES.SPACE_BAR) {
          submitHubSpotFormForSelectOrg(userData, organization);
          setSelectedOrgId(organization.id);
          track.click({
            ...trackingData,
            meta: {
              id: organization.id,
            },
          });
        }
      }}
    >
      <EntityCard key={organization?.id} entity={organization} entityType={ENTITY_TYPES.ORGANIZATION} withoutLink />
      {quickView ? (
        <div></div>
      ) : (
        <div className={classes.organizationCard__arrowRight}>
          <SpriteIcon icon="arrow_forward" size="16" />
        </div>
      )}
    </div>
  );
};

const WhiteListedOrgList = ({
  orgList,
  setSelectedOrgId,
  trackingData,
  isAosVerification,
  submitHubSpotFormForSelectOrg,
  userData,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { data: profileAccesses } = useProfileAccesses();

  const filteredOrgList = useMemo(() => {
    if (!orgList?.data) {
      return [];
    }
    const organizationIds = profileAccesses?.data?.map(access => access?.organization?.id) || [];
    return isAosVerification ? orgList.data.filter(org => !organizationIds.includes(org.id)) : orgList.data;
  }, [orgList.data, profileAccesses?.data, isAosVerification]);

  const renderOrgCards = (organizations, isDisabled = false) => (
    <div
      className={classnames(classes.whiteListedOrgList__cardsWrapper, {
        [classes.disabledCards]: isDisabled,
      })}
    >
      {organizations.map((org, index) => (
        <OrganizationCard
          key={index}
          organization={org}
          setSelectedOrgId={setSelectedOrgId}
          trackingData={trackingData}
          submitHubSpotFormForSelectOrg={submitHubSpotFormForSelectOrg}
          userData={userData}
        />
      ))}
    </div>
  );

  return (
    <div className={classes.whiteListedOrgList}>
      <Typography size="18" weight="medium" className={classes.whiteListedOrgList__title}>
        {filteredOrgList.length > 0
          ? t(`${TP}.WHITE_LISTED_MULTIPLE_DOMAIN_TITLE`)
          : t(`${TP}.WHITE_LISTED_MULTIPLE_DOMAIN_EMPTY`)}
      </Typography>
      {filteredOrgList.length > 0
        ? renderOrgCards(filteredOrgList)
        : orgList?.data?.length > 0 && renderOrgCards(orgList?.data, true)}
    </div>
  );
};

const SelectedOrgForPreview = ({ selectedOrg, trackingData = {} }) => {
  const { showQuickView } = useQuickView();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const handlePreview = e => {
    e.preventDefault();
    showQuickView({
      type: QUICK_VIEW_TYPES.ENTITY,
      data: {
        entityId: selectedOrg?.id,
        entityType: ENTITY_TYPES.ORGANIZATION,
      },
    });
  };
  return (
    <div className={classes.whitelistedSelectedOrg}>
      <EntityCard key={selectedOrg?.id} entity={selectedOrg} entityType={ENTITY_TYPES.ORGANIZATION} withoutLink />
      <QuaternaryButton
        leftIcon={<SpriteIcon icon="visibility" size="16" />}
        onClick={handlePreview}
        trackingData={{
          ...trackingData,
          subComponent: SUB_COMPONENTS.PROFILE_PREVIEW_CTA,
        }}
      >
        <Typography size="12" color="secondary">
          {t(`${TP}.PROFILE_MANAGE_PREVIEW`)}
        </Typography>
      </QuaternaryButton>
    </div>
  );
};

const GetJobInfo = ({
  updateRegistration,
  selectedOrgId,
  ownerProfile,
  trackingData = {},
  setJobInfo,
  submitHubSpotFormForDesignationAndJob,
  whiteListedOrganizations,
  userData,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const [professionQuery, setProfessionQuery] = useState('');
  const [orgDesignationQuery, setOrgDesignationQuery] = useState('');
  const [formValues, setFormValues] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const getDefaultProfession = ownerProfile?.profile?.professions?.[0]?.profession || null;
  const selectedOrg = useMemo(() => whiteListedOrganizations?.data?.find(org => org?.id === selectedOrgId), [
    selectedOrgId,
    whiteListedOrganizations?.data,
  ]);
  const { data } = useQuery(
    orgQueries.getManagementAndStaff({
      id: selectedOrgId,
      queryConfig: {
        enabled: !!selectedOrgId,
        select: response => response?.data[0] || {},
      },
      params: { queryParams: { profile_id: ownerProfile?.profile?.id } },
    }),
  );

  const { data: orgProfessions, isLoading: isOrgProfessionsLoading } = useQuery(
    queries.getProfessions({
      tags: TAG_SLUGS.ORGANIZATIONS,
      tagType: TAG_TYPE.REGISTRATION_COHORT,
      isSelect: true,
      querySearch: professionQuery,
    }),
  );

  const { data: orgDesignations, isLoading: isOrgDesignationsLoading } = useQuery(
    orgQueries.getOrgDesignations({
      limit: 20,
      filters: {
        query: orgDesignationQuery,
      },
      queryConfig: {
        select: response => ({
          data: response?.data?.map(option =>
            getOption(option, 'id', 'name', { id: option?.id, slug: option?.slug, name: option?.name }),
          ),
        }),
      },
    }),
  );

  const checkFormValidation = values => {
    const requiredFields = FORM_FIELDS.map(field => field.name);
    const isValid = requiredFields.every(field => values[field] && values[field].value);
    setIsFormValid(isValid);
  };
  useEffect(() => {
    if (data?.roles || getDefaultProfession?.id) {
      const role = data?.roles?.[0];
      const designation = role?.designation;
      const profession = role?.profession || getDefaultProfession;

      const updatedValues = {
        ...formValues,
        [FORM_FIELD_NAME.DESIGNATION]: designation?.id
          ? {
              id: designation.id,
              slug: designation.slug,
              name: designation.name,
              value: designation.id,
              label: designation.name,
            }
          : null,
        [FORM_FIELD_NAME.PROFESSION]: profession?.id
          ? {
              id: profession.id,
              slug: profession.slug,
              name: profession.name,
              value: profession.id,
              label: profession.name,
            }
          : null,
      };

      setFormValues(updatedValues);
      checkFormValidation(updatedValues); // NOTE: Validate after setting initial values
    }
  }, [data?.roles, getDefaultProfession]);

  useEffect(() => {
    checkFormValidation(formValues); // NOTE: Revalidate whenever formValues change
  }, [formValues]);

  const handleFormChange = (name, value) => {
    const updatedFormValues = { ...formValues, [name]: value };
    setFormValues(updatedFormValues);
  };

  const handleInputChange = (fieldName, inputValue) => {
    switch (fieldName) {
      case FORM_FIELD_NAME.PROFESSION:
        setProfessionQuery(inputValue);
        break;
      case FORM_FIELD_NAME.DESIGNATION:
        setOrgDesignationQuery(inputValue);
        break;
      default:
        console.error('Unhandled field:', fieldName);
    }
  };

  const getLoadingState = fieldName => {
    switch (fieldName) {
      case FORM_FIELD_NAME.PROFESSION:
        return isOrgProfessionsLoading;
      case FORM_FIELD_NAME.DESIGNATION:
        return isOrgDesignationsLoading;
      default:
        return false;
    }
  };

  const handleJobInfoAction = () => {
    setJobInfo(formValues);
    updateRegistration({ formData: formValues });
    submitHubSpotFormForDesignationAndJob(
      userData,
      selectedOrg,
      formValues.designation.name,
      formValues.profession.name,
    );
    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.CONTINUE_CTA,
    });
  };

  return (
    <div className={classes.getJobInfo}>
      <Typography size="18" weight="medium" className={classes.getJobInfo__title}>
        {t(`${TP}.WHITE_LISTED_USER_JOB_DETAILS`)}
      </Typography>

      <div className={classes.getJobInfo__form}>
        {FORM_FIELDS.map(field => (
          <AutocompleteDropDown
            key={field.name}
            name={field.name}
            label={t(field.label)}
            options={field.name === 'profession' ? orgProfessions?.data || [] : orgDesignations?.data || []}
            value={formValues[field.name] || null}
            onChange={(e, value) => handleFormChange(field.name, value)}
            onInputChange={(e, input) => handleInputChange(field.name, input)}
            isLoading={getLoadingState(field?.name)}
            renderOption={(innerProps, option) => (
              <li {...innerProps}>
                <Typography size="12">{option.name}</Typography>
              </li>
            )}
            clearOnBlur
            fullWidth
            disableClearable
            InputProps={{
              endAdornment: <SpriteIcon icon="expand_more" size={20} />,
            }}
          />
        ))}
      </div>

      <div className={classes.getJobInfo__formAction}>
        <PrimaryButton fullWidth shape="rectangle" disabled={!isFormValid} onClick={handleJobInfoAction}>
          {t(`${TP}.m_CONTINUE`)}
        </PrimaryButton>
      </div>
    </div>
  );
};

export const PreferencesSelection = ({
  createOrgEmployee,
  isLoading,
  trackingData,
  submitHubSpotFormForPreference,
  selectedOrgId,
  userData,
  whiteListedOrganizations,
  preferencesInit = [],
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const selectedOrg = useMemo(() => whiteListedOrganizations?.data?.find(org => org?.id === selectedOrgId), [
    selectedOrgId,
    whiteListedOrganizations?.data,
  ]);
  const { data: preferences, isLoading: isPreferencesLoading } = useQuery(
    queries.getPreferences({
      filters: {
        entity_type: 'organizationEmployee',
      },
      queryConfig: {
        select: response => ({
          data: response?.data,
        }),
      },
    }),
  );

  useEffect(() => {
    setSelectedPreferences(prevSelected => {
      if (prevSelected.length > 0) {
        return prevSelected; // NOTE: Preserve user's selections
      }
      return preferencesInit.length > 0 ? preferencesInit : preferences?.data || [];
    });
  }, [preferencesInit, preferences?.data]);

  const handleClick = option => {
    setSelectedPreferences(prevSelected => {
      const isOptionSelected = prevSelected.some(preference => preference.id === option.id);
      const newSelection = isOptionSelected
        ? prevSelected.filter(preference => preference.id !== option.id) // Unselect
        : [...prevSelected, option];
      return newSelection;
    });

    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.PREFERENCE_CHECKBOX,
      meta: { id: option?.id },
    });
  };

  if (isPreferencesLoading) {
    return <Loader />;
  }

  const handleContinue = () => {
    createOrgEmployee({ selectedPreferences });
    if (submitHubSpotFormForPreference) {
      submitHubSpotFormForPreference(userData, selectedOrg, selectedPreferences);
    }

    track.click({
      ...trackingData,
      subComponent: SUB_COMPONENTS.CONTINUE_CTA,
    });
  };
  return (
    <div className={classes.preferenceSelection_wrapper}>
      <Typography size="18" weight="medium" className={classes.preferenceSelection__title}>
        {t(`${TP}.WHITE_LISTED_DOMAIN_USER_PREFERENCES`)}
      </Typography>
      {preferences?.data?.map(preference => (
        <div className={classes.preferenceSelection__checklist} key={preference.id}>
          <Checkbox
            onChange={() => handleClick(preference)}
            label={preference.text}
            checked={selectedPreferences.some(option => option.id === preference.id)}
            size="small"
            className={classes.preferenceSelection__checklist_checkbox}
          />
        </div>
      ))}
      <div className={classes.preferenceSelection__formAction}>
        <PrimaryButton fullWidth shape="rectangle" onClick={handleContinue} isDisabled={isLoading} loading={isLoading}>
          {t(`${TP}.m_CONTINUE`)}
        </PrimaryButton>
      </div>
    </div>
  );
};

const trackingData = {
  section: SECTIONS.SALES_PROSPECT_REGISTRATION,
};
const trackingDataPreferences = {
  ...trackingData,
  component: COMPONENTS.PREFERENCES_SELECTION,
};

const submitHubSpotFormForSelectOrg = async (userData, SelectedOrg) => {
  try {
    const hubspotPayload = [
      { name: 'user_id__database_', value: userData?.id },
      { name: 'email', value: userData?.email },
      { name: 'sales_prospect_domain', value: true },
      { name: 'organisation_name__registration_only_', value: SelectedOrg?.name },
    ];

    const formId =
      process.env.APP_ENV === 'production'
        ? '0afc1d34-fa54-463d-8496-0ca997518f57'
        : '0f920788-2e79-4d56-bfc9-b80cf6d69ace';

    submitHubspotForm(process.env.HUBSPOT_REGISTER_KEY, formId, { fields: hubspotPayload });
  } catch (error) {
    console.error('Hubspot form submission failed - submitHubSpotFormForSelectOrg:', error);
  }
};

const submitHubSpotFormForDesignationAndJob = async (userData, SelectedOrg, jobRole, profession) => {
  try {
    const hubspotPayload = [
      { name: 'user_id__database_', value: userData?.id },
      { name: 'email', value: userData?.email },
      { name: 'sales_prospect_domain', value: true },
      { name: 'organisation_name__registration_only_', value: SelectedOrg?.name },
      { name: 'organisation_job___role', value: jobRole },
      { name: 'primary_profession_v4_registration', value: profession },
    ];

    const formId =
      process.env.APP_ENV === 'production'
        ? '1999f57d-ca72-4870-8432-decc014ccfcc'
        : '0f920788-2e79-4d56-bfc9-b80cf6d69ace';

    submitHubspotForm(process.env.HUBSPOT_REGISTER_KEY, formId, { fields: hubspotPayload });
  } catch (error) {
    console.error('Hubspot form submission failed - submitHubSpotFormForDesignationAndJob:', error);
  }
};

const submitHubSpotFormForPreference = async (userData, SelectedOrg, preference = []) => {
  try {
    const preferenceMapping = {
      37: 'casting_tool',
      38: 'rentals',
      39: 'audience_report',
    };
    const preferenceFields = preference
      .map(pref => ({
        name: 'area_of_work_or_interest__sales_prospect_',
        value: preferenceMapping[pref.id] || '',
      }))
      .filter(item => item.value);

    const hubspotPayload = [
      { name: 'user_id__database_', value: userData?.id },
      { name: 'email', value: userData?.email },
      { name: 'sales_prospect_domain', value: true },
      { name: 'organisation_name__registration_only_', value: SelectedOrg?.name },
      ...preferenceFields,
    ];

    const formId =
      process.env.APP_ENV === 'production'
        ? 'b5ef9c07-5483-4c19-b729-f1bb2d3120a5'
        : 'e706ba2d-ce9e-40b8-b67d-8da4c155aa36';

    await submitHubspotForm(process.env.HUBSPOT_REGISTER_KEY, formId, { fields: hubspotPayload });
  } catch (error) {
    console.error('HubSpot form submission failed - submitHubSpotFormForPreference:', error);
  }
};

const NewProfessionalEmail = ({
  whiteListedOrganizations,
  setIsModalCloseDisabled,
  additionalEmailData,
  isAosVerification,
}) => {
  const track = useTracking();
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [hasJobInfo, setHasJobInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobInfo, setJobInfo] = useState({});
  const { navigate } = usePageContext();
  const userData = useUserData();
  const { data: profileAccesses, ownerProfile } = useProfileAccesses();
  const refreshUserQueries = useRefetchUserQueries();

  const { data: registrations, refetch: refetchRegistration, isLoading: isLoadingRegistration } = useQuery(
    queries.getRegistrations({
      userId: userData?.id,
      queryConfig: { enabled: !!userData?.id },
    }),
  );
  useEffect(() => {
    setIsModalCloseDisabled(true);
  }, [setIsModalCloseDisabled]);

  useEffect(() => {
    if (isAosVerification) {
      return;
    }
    const organizationIds = profileAccesses?.data?.reduce((ids, access) => {
      const id = access?.organization?.id;
      if (id !== undefined) {
        ids.push(id);
      }
      return ids;
    }, []);

    if (whiteListedOrganizations?.data?.length === 1 && organizationIds?.length === 0) {
      setSelectedOrgId(whiteListedOrganizations?.data[0].id);
    }
    if (organizationIds?.length === 1) {
      setSelectedOrgId(organizationIds[0]);
    }
  }, [isAosVerification, profileAccesses?.data, whiteListedOrganizations]);

  const { mutate: updateRegistrationMutation, isLoading: updateRegistrationLoading } = useUpdateRegistrationMutation({
    onSuccess: () => {
      setHasJobInfo(true);
      refetchRegistration();
    },
    onError: error => {
      enqueueSnackbar(errorMessageConverter(error), { variant: SNACKBAR_VARIANTS.ERROR });
    },
  });

  useEffect(() => {
    refetchRegistration();
  }, [refetchRegistration, userData?.id]);

  const selectedRegistration = registrations?.data?.find(
    reg => reg?.meta_data?.contact?.email === additionalEmailData?.email,
  );

  // NOTE:  Fallback to the first registration if no match is found
  const registration = selectedRegistration || registrations?.data?.[0];

  const { mutateAsync: createOrganizationEmployeeMutation } = useMutation(orgQueries.createOrganizationEmployee());

  const updateRegistration = useCallback(
    ({ formData = {} }) => {
      let payload;

      if (formData?.profession?.id && formData?.designation?.id) {
        // NOTE: Payload when formData is present
        const updatedEntities = {
          ...registration?.entities,
          organizationEmployee: {
            profession: { id: formData.profession.id },
            designation: { id: formData.designation.id },
          },
          organization: {
            ...registration?.entities.organization,
            id: selectedOrgId,
          },
        };

        payload = {
          ...registration,
          step: REGISTRATION_STEPS.whiteListedDomainPreference,
          entities: updatedEntities,
        };
      } else {
        // NOTE: Payload when formData is not present final step
        payload = {
          ...registration,
          step: REGISTRATION_STEPS.congratulationForm,
        };
      }

      updateRegistrationMutation(payload);
    },
    [registration, selectedOrgId, updateRegistrationMutation],
  );

  const createOrgEmployee = useCallback(
    async ({ selectedPreferences }) => {
      const payload = getOrgEmployeePayload(
        userData,
        registration,
        selectedOrgId,
        selectedPreferences,
        ownerProfile,
        jobInfo,
      );
      setIsLoading(true);

      try {
        await createOrganizationEmployeeMutation(payload, {
          onError: error => {
            enqueueSnackbar(errorMessageConverter(error), { variant: SNACKBAR_VARIANTS.ERROR });
            track.actionCallback({
              ...trackingDataPreferences,
              meta: {
                step: ACTION_CALLBACK_STEPS.CREATE_EMPLOYEE,
                actionStatus: ACTION_CALLBACK_STATUS.FAILURE,
              },
            });
          },
          onSuccess: async res => {
            track.actionCallback({
              ...trackingDataPreferences,
              meta: {
                step: ACTION_CALLBACK_STEPS.CREATE_EMPLOYEE,
                actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
              },
            });
            updateRegistration({ formData: { profession: {}, designation: {} } });
            await refreshUserQueries();
            navigate.to(
              navigate.getLinkProps({
                entity: res?.data?.organization,
                entityType: ENTITY_TYPES.ORGANIZATION,
                onlyLinkProps: true,
              }),
            );
          },
        });
      } catch (error) {
        enqueueSnackbar(errorMessageConverter(error), { variant: SNACKBAR_VARIANTS.ERROR });
      } finally {
        setIsLoading(false);
      }
    },
    [
      createOrganizationEmployeeMutation,
      jobInfo,
      navigate,
      ownerProfile,
      refreshUserQueries,
      registration,
      selectedOrgId,
      track,
      updateRegistration,
      userData,
    ],
  );

  const selectedOrg = useMemo(() => {
    if (!selectedOrgId) {
      return null;
    }

    if (whiteListedOrganizations?.data?.length === 1) {
      submitHubSpotFormForSelectOrg(userData, whiteListedOrganizations?.data[0]);
      setSelectedOrgId(whiteListedOrganizations?.data?.[0].id);
      return whiteListedOrganizations?.data[0];
    }
    return whiteListedOrganizations?.data?.find(org => org.id === selectedOrgId);
  }, [selectedOrgId, whiteListedOrganizations?.data]);

  const renderNewProfessionStep = useMemo(() => {
    if (selectedOrg) {
      return (
        <>
          <SelectedOrgForPreview selectedOrg={selectedOrg} trackingData={trackingData} />
          {hasJobInfo ? (
            <PreferencesSelection
              createOrgEmployee={createOrgEmployee}
              isLoading={isLoading || isLoadingRegistration || updateRegistrationLoading}
              trackingData={trackingDataPreferences}
              submitHubSpotFormForPreference={submitHubSpotFormForPreference}
              selectedOrgId={selectedOrgId}
              userData={userData}
              whiteListedOrganizations={whiteListedOrganizations}
            />
          ) : (
            <GetJobInfo
              updateRegistration={updateRegistration}
              setHasJobInfo={setHasJobInfo}
              setJobInfo={setJobInfo}
              selectedOrgId={selectedOrgId}
              ownerProfile={ownerProfile}
              whiteListedOrganizations={whiteListedOrganizations}
              trackingData={{ ...trackingData, component: COMPONENTS.GET_JOB_INFO }}
              submitHubSpotFormForDesignationAndJob={submitHubSpotFormForDesignationAndJob}
              userData={userData}
            />
          )}
        </>
      );
    }
    return (
      <WhiteListedOrgList
        orgList={whiteListedOrganizations}
        setSelectedOrgId={setSelectedOrgId}
        trackingData={{ ...trackingData, component: COMPONENTS.WHITE_LISTED_DOMAINS_LIST }}
        isAosVerification={isAosVerification}
        userData={userData}
        submitHubSpotFormForSelectOrg={submitHubSpotFormForSelectOrg}
      />
    );
  }, [
    selectedOrg,
    whiteListedOrganizations,
    isAosVerification,
    userData,
    hasJobInfo,
    createOrgEmployee,
    isLoading,
    isLoadingRegistration,
    updateRegistrationLoading,
    updateRegistration,
    selectedOrgId,
    ownerProfile,
  ]);

  return <div className={classes.newProfessionalEmail}>{renderNewProfessionStep}</div>;
};

export default NewProfessionalEmail;
